.nav-link {
  font-size: 0.9em !important;
}

.header {
  margin-bottom: 60px;
  background: linear-gradient(to top, rgb(243, 246, 255), #fdfdfd);
}

.padding-top {
  padding-top: 100px;
}
