@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;600;700;800&display=swap');

h1 { 
    font-family: 'Manrope', sans-serif;
}
body {
    font-family: 'Manrope', sans-serif;
}

.fs-sm {
    font-size: 0.875rem !important;
}

.hero {
    font-size: 1.15rem !important;
    padding-top: 6rem;
 }
 
 .giz {
    padding-top: 10px
 }
.service, .address {
    font-size: 14px;
    font-weight: 600;
}
.address {
    padding-bottom: 0.8rem;
}
.company {
    padding-top: 1rem;
    font-weight: 900;
}
.addressBook {
    padding-right: 0.5rem;
}